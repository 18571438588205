<template>
  <b-row>
    <loading
      :active="isLoading"
      loader="bars"
      color="#7367f0"
      :is-full-page="fullPage"
    />
    <b-form
      ref="form"
    >
      <b-col cols="12">
        <b-card :title="divisionName">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Description"
              >
                <quill-editor
                  v-model="description"
                  :options="snowOption"
                  @change="onEditorChange($event)"
                  @ready="onEditorReady($event)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Attachment"
              >
                <a
                  :href="`https://servo.blob.core.windows.net/materials/${file}`"
                  target="_blank"
                >
                  {{ file }}
                </a>
                <b-form-file
                  id="extension"
                  ref="attachment"
                  v-model="attachment"
                  accept=".pdf"
                />
                <small>Do not exceed 5MB</small>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card
          ref="scheduleForm"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Schedules & Rooms
              </h5>
              <small class="text-muted">
                Please set your multiple schedules and rooms details here.
              </small>
            </b-col>
            <b-col
              cols="12"
            >
              <!-- Row Loop -->
              <b-row
                v-for="(item, index) in items"
                :id="item.id"
                :key="item.id"
                ref="row"
              >
                <!-- Audition room -->
                <b-col
                  v-if="ministryType === 1"
                  md="4"
                >
                  <b-form-group
                    label="Audition Room"
                    label-for="audition-room"
                  >
                    <b-form-input
                      id="audition-room"
                      v-model="item.audition_room"
                      type="text"
                    />
                  </b-form-group>
                </b-col>

                <!-- Audition schedule -->
                <b-col
                  v-if="ministryType === 1"
                  md="4"
                >
                  <b-form-group
                    label="Audition Date"
                    label-for="audition-date"
                  >
                    <b-form-datepicker
                      v-model="item.audition_date"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="ministryType === 1"
                  md="4"
                >
                  <b-form-group
                    label="Audition Time"
                    label-for="audition-time"
                  >
                    <b-form-timepicker
                      v-model="item.audition_time"
                    />
                  </b-form-group>
                </b-col>

                <!-- Interview room -->
                <b-col
                  md="4"
                >
                  <b-form-group
                    label="Interview Room"
                    label-for="interview-room"
                  >
                    <b-form-input
                      id="interview-room"
                      v-model="item.interview_room"
                      type="text"
                    />
                  </b-form-group>
                </b-col>

                <!-- Audition schedule -->
                <b-col
                  md="4"
                >
                  <b-form-group
                    label="Interview Date"
                    label-for="interview-date"
                  >
                    <b-form-datepicker
                      v-model="item.interview_date"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="4"
                >
                  <b-form-group
                    label="Interview Time"
                    label-for="interview-time"
                  >
                    <b-form-timepicker
                      v-model="item.interview_time"
                    />
                  </b-form-group>
                </b-col>

                <!-- Remove Button -->
                <b-col
                  md="4"
                >
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    @click="removeItem(item, index)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-25"
                    />
                    <span>Delete</span>
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="repeateAgain"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                    />
                    <span>Add New</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-button
          variant="primary"
          class="mr-1"
          type="submit"
          @click.prevent="submitForm"
        >
          Submit
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          variant="outline-secondary"
          :to="{ name: 'servolution-ministry-setup', params: { id: $route.params.eventId } }"
        >
          Cancel
        </b-button>
      </b-col>
    </b-form>
  </b-row>
</template>

<script>
// import BCardCode from '@core/components/b-card-code'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BFormFile,
  BForm,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormDatepicker,
  BFormTimepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import _ from 'lodash'
import * as moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import Cookies from 'js-cookie'
// import CryptoJS from 'crypto-js'
import Loading from 'vue-loading-overlay'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { heightTransition } from '@core/mixins/ui/transition'
import 'vue-loading-overlay/dist/vue-loading.css'
import { quillEditor } from 'vue-quill-editor'
import { isUserLoggedIn } from '@/auth/utils'

export default {
  components: {
    // ValidationProvider,
    // ValidationObserver,
    BFormInput,
    BFormFile,
    BFormGroup,
    BFormDatepicker,
    BFormTimepicker,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    Loading,
    quillEditor,
  },
  directives: {
      Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      isLoading: true,
      fullPage: false,
      divisionName: '',
      ministryType: 1,
      snowOption: {
        theme: 'snow',
      },
      description: '',
      descriptionLength: 1,
      attachment: null,
      file: '',
      removedItems: [],
      previousItems: [],
      items: [],
      nextRepeaterId: 2,
      ministryId: '',
      ministry: '',
      churches: [],
      churchOptions: [],
      position: [],
      positionOptions: [
        {
            name: 'LEADER',
        },
        {
            name: 'MEMBER',
        },
      ],
      status: [],
      statusOptions: [
        {
            id: 0,
            name: 'Need rereg',
        },
        {
            id: 1,
            name: 'Need verification for reregistration',
        },
        {
            id: 2,
            name: 'Active',
        },
      ],
      // divisions: [],
      // divisionOptions: [],
      end_year: null,
      end_reason: null,
      // status: 0,
      required,
    }
  },
  mounted() {
    // Get the volunteer ldata
    this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/ministry/setup/details/${this.$route.params.eventId}/${this.$route.params.divisionId}`)
      .then(async details => {
        console.log(details)

        const event = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/events/${this.$route.params.eventId}`)

        // Set the variables
        if (_.isEmpty(details.data)) {
          const ministry = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/church/ministry/${event.data.churchId}/${this.$route.params.divisionId}`)
          this.divisionName = ministry.data.divisions.name
          this.ministryType = ministry.data.ministryTypeId

          // Set default items
          this.items = [{
            id: 1,
            audition_room: '',
            audition_date: '',
            audition_time: '',
            interview_room: '',
            interview_date: '',
            interview_time: '',
          }]
        } else {
          this.divisionName = details.data[0].division_name
          this.ministryType = details.data[0].ministry_type_id

          // Set description
          this.description = details.data[0].description

          // Attachment
          this.file = details.data[0].attachments

          // Set default items
          _.forEach(details.data, data => {
            // Add to upcoming items
            this.items.push({
              id: data.id,
              audition_room: data.audition_room,
              audition_date: moment(data.audition_schedule).format('YYYY-MM-DD'),
              audition_time: moment(data.audition_schedule).format('HH:mm'),
              interview_room: data.interview_room,
              interview_date: moment(data.interview_schedule).format('YYYY-MM-DD'),
              interview_time: moment(data.interview_schedule).format('HH:mm'),
            })

            // Add to previous items
            this.previousItems.push({
              id: data.id,
              audition_room: data.audition_room,
              audition_date: moment(data.audition_schedule).format('YYYY-MM-DD'),
              audition_time: moment(data.audition_schedule).format('HH:mm'),
              interview_room: data.interview_room,
              interview_date: moment(data.interview_schedule).format('YYYY-MM-DD'),
              interview_time: moment(data.interview_schedule).format('HH:mm'),
            })
          })
        }

        // Hide loading
        this.isLoading = false
      })
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    onEditorReady(quill) {
      this.descriptionLength = quill.getLength()
    },
    onEditorChange({ quill, html, text }) {
      console.log(html)
      console.log(text)
      this.descriptionLength = quill.getLength()
    },
    repeateAgain() {
      this.items.push({
        id: this.nextRepeaterId += this.nextRepeaterId,
        audition_room: '',
        audition_date: '',
        audition_time: '',
        interview_room: '',
        interview_date: '',
        interview_time: '',
      })
    },
    removeItem(item, index) {
      // Check if it's on previous item
      const previousItem = _.find(this.previousItems, { id: item.id })
      if (previousItem) {
        this.removedItems.push(item.id)
      }

      // Remove
      this.items.splice(index, 1)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.scheduleForm.scrollHeight)
      })
    },
    async submitForm() {
      // Check for cookies before submit
      // Get logged in status
      const isLoggedIn = isUserLoggedIn()
      // If not logged in, then redirect to login page
      if (!isLoggedIn) {
        return this.$router.replace('/login')
      }

      // Show loading bar
      this.isLoading = true

      // Validate for description
      /* if (this.descriptionLength === 1) {
        // Hide loading bar
        this.isLoading = false
        
        // Alert
        return this.$swal({
          title: 'Error',
          text: ' Please insert the description.',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } */

      // Validate for attachment
      // if (!this.attachment && !this.file) {
      //   // Hide loading bar
      //   this.isLoading = false
        
      //   // Alert
      //   return this.$swal({
      //     title: 'Error',
      //     text: ' Please upload the attachment first.',
      //     icon: 'error',
      //     customClass: {
      //       confirmButton: 'btn btn-primary',
      //     },
      //     buttonsStyling: false,
      //   })
      // }

      // Validate attachment size not to exceed 5MB
      if (this.attachment && this.attachment.size > 5000 * 1000) {
        // Hide loading bar
        this.isLoading = false
        
        // Alert
        return this.$swal({
          title: 'Error',
          text: 'The file size is too big. Please upload the smaller size (not exceed 5MB)',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }

      // Check if user has access
      if (!this.$can('update', 'servolution_ministry_setup')) {
        this.isLoading = false
        return this.$router.replace({ name: 'misc-not-authorized' })
      }

      // Upload attachment first
      let attachment = null
      if (this.attachment) {
        const formData = new FormData()
        formData.append('attachment', this.attachment)
        attachment = await this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/event/attachment`, formData, {
          headers: {
            'content-Type': 'multipart/form-data',
          },
        })
      }

      // Check if there is no previous items
      if (_.isEmpty(this.previousItems)) {
        // Create new record
        _.forEach(this.items, async schedule => {
          // Insert the basic info
          const data = {
            servolutionEventsId: this.$route.params.eventId,
            divisionsId: this.$route.params.divisionId,
            description: this.description,
          }

          // Check if there is attachment
          if (attachment) {
            data.attachments = attachment?.data.blobName
          }
          
          // Insert the schedule & room
          if (schedule.audition_room) { data.auditionRoom = schedule.audition_room }
          if (schedule.audition_date && schedule.audition_time) { data.auditionSchedule = moment(`${schedule.audition_date} ${schedule.audition_time}`) }
          if (schedule.interview_room) { data.interviewRoom = schedule.interview_room }
          if (schedule.interview_date && schedule.interview_time) { data.interviewSchedule = moment(`${schedule.interview_date} ${schedule.interview_time}`) }

          await this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/event/details`, data)
        })
      } else {
        // Check if there's a new schedule
        // If there's then insert the new record for the same division
        // If there'nt then update the existing
        _.forEach(this.items, async schedule => {
          // Check with the previous items
          const existingDetails = _.find(this.previousItems, { id: schedule.id })
          
          // Prepare the data
          const data = {
            servolutionEventsId: this.$route.params.eventId,
            divisionsId: this.$route.params.divisionId,
            description: this.description,
            updatedAt: new Date(),
          }

          if (this.attachment) {
            data.attachments = attachment.data.blobName
          } else if (this.file) {
            data.attachments = this.file
          }

          // Update the schedule & room
          if (schedule.audition_room) { data.auditionRoom = schedule.audition_room }
          if ((schedule.audition_date && schedule.audition_date !== 'Invalid date') && (schedule.audition_time && schedule.audition_time !== 'Invalid date')) { data.auditionSchedule = moment(`${schedule.audition_date} ${schedule.audition_time}`) }
          if (schedule.interview_room) { data.interviewRoom = schedule.interview_room }
          if ((schedule.interview_date && schedule.interview_date !== 'Invalid date') && (schedule.interview_time && schedule.interview_time !== 'Invalid date')) { data.interviewSchedule = moment(`${schedule.interview_date} ${schedule.interview_time}`) }

          // Existing
          if (existingDetails) {
            // Update
            await this.$http.patch(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/event/details/${schedule.id}`, data)
          } else {
            // Update and insert new record
            await this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/event/details`, data)
          }
        })

        // Looping for items removal
        _.forEach(this.removedItems, async eventDetailsId => {
          await this.$http.patch(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/event/details/${eventDetailsId}`, { updatedAt: new Date(), deletedAt: new Date() })
        })
      }

      // Stop loading and redirect
      this.isLoading = false
      this.$router.replace({ name: 'servolution-ministry-setup', params: { id: this.$route.params.eventId } })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'Data has been updated successfully.',
            },
          })
        })
    },
  },
}
</script>
<style lang="scss" scoped>
  @import '@core/scss/vue/libs/quill.scss';
  .repeater-form {
    overflow: hidden;
    transition: .35s height;
  }
</style>